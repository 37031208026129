<template>
  <div class="m-rule-cont">
    <h3>活动规则</h3>
  <p> 活动期间内，凡购买今麦郎“马牙沙”促销装，打开瓶盖，打开手机微信扫一扫，扫描瓶盖内二维码参与活动，即有机会获得微信红包，综合中奖率100%。</p>
  <p class="bold"> 参与方式：</p>
  <p> 打开手机微信扫一扫，扫描瓶盖内二维码，扫码后按提示参与活动。</p>
  <p class="bold"> 活动时间：</p>
  <p> 2022年6月1日00:00起至2023年9月30日18:00止。</p>
  <p class="bold"> 奖项设置：</p>
  <p> 微信红包0.68元（中奖率10%）；</p>
  <p> 微信红包0.38元（中奖率90%）；</p>
  <p> （综合中奖率100%）；</p>
  <p> 单个消费者当日获得微信红包个数上限为10个。</p>
  <p class="bold"> 兑奖说明：</p>
  <p> 微信现金红包为即时到账，您可在“零钱明细”中查询中奖金额，如有问题可咨询本次活动热线。</p>
  <p class="bold"> 活动热线：</p>
  <p> 400-707-1294（活动期间周一至周五09:00-18:00，法定节假日除外）。</p>
  <p> 本次活动产品投放量共计约2400万瓶，微信红包产品共计约1200万瓶，综合中奖率100%</p>
  <p class="bold"> 注意事项：</p>
  <p> 请在参与本活动前仔细阅读本规则中的各项条款，如果您参与了本活动，则今麦郎公司（以下简称主办方）认为您已理解并同意该规则中的各条款。</p>
  <p> 1.活动区域：全国（不包含港、澳、台）；</p>
  <p> 2.主办方对本次活动促销产品及其附属物（如产品包装、二维码等）的真实性和有效性有鉴定及确认权；</p>
  <p> 3.盖内二维码、奖品以实物为准，活动站点及相关宣传资料上提供的图片仅供参考；</p>
  <p> 4.本次促销活动，如遇不可抗力事件，包括但不限于地震、台风、水灾或其他自然灾害、火灾、爆炸事故、骚乱、战争、政府政策的改变、任何国家主管机关就此次活动做出任何判决、决定、命令，或未能合理控制的任何其他不可预见事件，主办方有权在不事先通知的前提下变更或取消本活动；</p>
  <p> 5.如果参与者在活动中使用任何不正当的手段参与活动，主办方有权在不事先通知的情况下取消其参与活动的资格，同时保留依法追究其法律责任的权利；</p>
  <p> 6.主办方保留对争议和异议的处理权利。主办方对于获奖者因参与本活动所发生的任何直接、间接费用和产生的任何损害不负任何责任；</p>
  <p> 7.本次扫码活动由北京尚杰智选科技有限公司提供技术支持；</p>
  <p> 8.本规则的解释与适用，以及与规则有关的任何争议，均以中华人民共和国法律为依据。</p>
  </div>
</template>

<script>
export default {
  name: 'BaseActivityRules',
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.m-rule-cont {
  font-size: 24px;
  line-height: 36px;
  color: #313131;

  h3 {
    font-size: 36px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #313131;
    line-height: 36px;
    text-align: center;
    margin-bottom: 30px;
  }

  h4 {
    font-weight: bold;
  }

  b {
    display: block;
    margin-top: 6px;
  }

  .bold {
    font-weight: bold;
    margin-top: 20px;
  }
}
</style>
